.tooltip-container {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: absolute;
  top: 35px;
  background: white;
  z-index: 2;
  min-width: 450px;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  padding: 20px;
}

.tooltip-scroll-container {
  max-height: 100vh;
  overflow: auto;
  position: relative;
  margin: 12px 0 5px;
}

.tooltip-scroll-container p:first-of-type {
  margin-top: 0;
}

.tooltip-scroll-container p:last-of-type {
  margin-bottom: 0;
}

.tooltip-container--bottom {}

.tooltip-container--right {
  right: auto;
  left: -35px;
  transform: none;
}

.tooltip-container--left {
  right: -35px;
  left: auto;
  transform: none;
}

.tooltip-container--top {}

.tooltip-container:after {
  content: '';
  display: block;
  position: absolute;
  top: -20px;
  right: 50%;
  width: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  transform: rotate(90deg) translateY(-50%);
}

.tooltip-container--right:after {
  left: 23px;
  right: auto;
}

.tooltip-container--left:after {
  right: 23px;
  left: auto;
}

/* // ring stuffs */

.ring-container {
  position: relative;
  cursor: pointer;
  width: 15px;
  height: 15px;
  /* background: hotpink; */
}

.ring-container--disabled {
  cursor: not-allowed;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -5px;
  top: -5px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0.0;
}

.circle {
  background-color: red
}

.ringring {
  border-color: red;
}

.circle--finished {
  background-color: gray;
  animation: none;
}

.ringring--finished {
  border-color: gray;
  animation: none;
}

.circle--active {
  background-color: #62bd19
}

.ringring--active {
  border-color: #62bd19;
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

.tooltip-header {
  max-width: calc(100% - 33px);
}