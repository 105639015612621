.submit-button {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 40px;
  min-width: 150px;
  line-height: 39px;
  border-radius: 4px;
  background: #e49c3f;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid transparent;
  padding: 0;
  cursor: pointer;
}

.submit-button[disabled] {
  cursor: not-allowed;
}

.submit-button:hover {
  background: white;
  border: 1px solid #e49c3f;
  color: #333;
}

.cta-small {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 30px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 29px;
  border-radius: 6px;
  background: #e49c3f;
  color: white;
  font-weight: bold;
  border: 1px solid transparent;
  cursor: pointer;
}

.cta-small:hover {
  background: white;
  border: 1px solid #e49c3f;
  color: #333;
}

.cta-small-secondary {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 30px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 29px;
  border-radius: 6px;
  background: white;
  border: 1px solid #e49c3f;
  color: white;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.cta-small-secondary:hover {
  border-color: #333;
}

.cta-small--disabled {
  border-color: #e8e8e8;
  background: #e8e8e8;
  cursor: not-allowed;
  pointer-events: none;
  color: #afafaf;
}