#questionaire {
  width: 100%;
  width: 500px;
  max-width: 100%;
  margin: 20px auto 50px;
  display: block;
}

/* // radio buttons */

.custom-checkradio-container {
  /* width: 600px;
  padding: 50px 0;
  margin: 0 auto; */
}

.custom-checkradio-container .radio, .custom-checkradio-container .checkbox {
  /* margin: 40px 0; */
}

/* Custom radio buttons */

.custom-checkradio-container input[type="radio"]+label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 13px;
  margin: 9px 0;
}

.custom-checkradio-container input[type="radio"]+label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -5px;
  left: 0;
  border: 2px solid #e49c3f;
  background-color: #fff;
  border-radius: 50%;
}

.custom-checkradio-container input[type="radio"] {
  display: none !important;
  *display: inline;
}

.custom-checkradio-container input[type="radio"]:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e49c3f;
}

/* Custom checkbox */

.custom-checkradio-container input[type="checkbox"]+label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 13px;
  margin: 9px 0;
}

.custom-checkradio-container input[type="checkbox"]+label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -3px;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 5px;
}

.custom-checkradio-container input[type="checkbox"] {
  display: none !important;
  *display: inline;
}

.custom-checkradio-container input[type="checkbox"]:checked+label:after {
  content: "✔";
  font-size: 20px;
  line-height: 20px;
  color: #b7b7b7;
  display: block;
  position: absolute;
  top: 0;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.alert {
  padding: 0px 10px;
  border: 2px solid #ec5c5c;
  margin-bottom: 30px;
  background: #f3efef;
  color: #212121;
  border-radius: 5px;
}