h1 {
  font-weight: bold;
  font-size: 20px;
}

.content-header {
  font-size: 20px;
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.content-sub-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.content-sub-sub-header {
  font-size: 16px;
  font-weight: 600;
}