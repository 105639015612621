body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.submit-button {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 40px;
  min-width: 150px;
  line-height: 39px;
  border-radius: 4px;
  background: #e49c3f;
  color: white;
  font-weight: bold;
  font-size: 15px;
  border: 1px solid transparent;
  padding: 0;
  cursor: pointer;
}

.submit-button[disabled] {
  cursor: not-allowed;
}

.submit-button:hover {
  background: white;
  border: 1px solid #e49c3f;
  color: #333;
}

.cta-small {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 30px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 29px;
  border-radius: 6px;
  background: #e49c3f;
  color: white;
  font-weight: bold;
  border: 1px solid transparent;
  cursor: pointer;
}

.cta-small:hover {
  background: white;
  border: 1px solid #e49c3f;
  color: #333;
}

.cta-small-secondary {
  border: none;
  /* border: 1px solid #963b3b; */
  height: 30px;
  padding: 0 15px;
  font-size: 13px;
  line-height: 29px;
  border-radius: 6px;
  background: white;
  border: 1px solid #e49c3f;
  color: white;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.cta-small-secondary:hover {
  border-color: #333;
}

.cta-small--disabled {
  border-color: #e8e8e8;
  background: #e8e8e8;
  cursor: not-allowed;
  pointer-events: none;
  color: #afafaf;
}
/* close icon */

.close {
  position: absolute;
  right: -8px;
  top: -6px;
  width: 31px;
  height: 31px;
  cursor: pointer;
  background: #e8e8e8;
  border-radius: 200px;
}

.close:hover {
  background: #ccc;
}

.close:before, .close:after {
  position: absolute;
  left: 14px;
  top: 6px;
  content: ' ';
  height: 20px;
  width: 3px;
  background-color: #333;
}

.close:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.close:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.tick {
  display: inline-block;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  height: 19px;
  width: 11px;
  border-bottom: 4px solid #78b13f;
  border-right: 4px solid #78b13f;
  margin-left: 20px;
}
h1 {
  font-weight: bold;
  font-size: 20px;
}

.content-header {
  font-size: 20px;
  margin: 0 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}

.content-sub-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}

.content-sub-sub-header {
  font-size: 16px;
  font-weight: 600;
}

/* site base */

* {
  box-sizing: border-box;
}

body {
  font-family: "Segoe UI Webfont", -apple-system, "Helvetica Neue", "Lucida Grande", "Roboto", "Ebrima", "Nirmala UI", "Gadugi", "Segoe Xbox Symbol", "Segoe UI Symbol", "Meiryo UI", "Khmer UI", "Tunga", "Lao UI", "Raavi", "Iskoola Pota", "Latha", "Leelawadee", "Microsoft YaHei UI", "Microsoft JhengHei UI", "Malgun Gothic", "Estrangelo Edessa", "Microsoft Himalaya", "Microsoft New Tai Lue", "Microsoft PhagsPa", "Microsoft Tai Le", "Microsoft Yi Baiti", "Mongolian Baiti", "MV Boli", "Myanmar Text", "Cambria Math";
  font-size: 15px;
  color: #000;
}

::-webkit-input-placeholder {
  color: #666;
}

:-moz-placeholder {
  color: #666;
}

::-moz-placeholder {
  color: #666;
}

:-ms-input-placeholder {
  color: #666;
}

h1 {
  font-size: 34px;
  color: #000;
  font-weight: 200;
}

a, a:hover, a:focus {
  color: #0072c6;
  text-decoration: none;
}

form {
  width: 100%;
  max-width: 356px;
  margin: 0 auto;
  display: block;
}

.check-wrap {
  position: relative;
  width: 100%;
  text-align: left;
  padding: 4px 0 4px 30px;
  margin-bottom: 18px;
}

input[type=text], input[type=password] {
  border: 2px solid rgba(0, 0, 0, .4);
  background-color: rgba(255, 255, 255, 0.4);
  color: #000;
  font-size: 15px;
  padding: 6px 8px;
  margin-bottom: 12px;
}

input[type=text]:focus, input[type=password]:focus {
  border-color: #0078d7;
  background-color: #fff;
}

input[type=checkbox] {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

input[type=text], input[type=password], input[type=submit] {
  width: 100%;
}

#logon {
  background-color: #0078d7;
  border: 0;
  color: #fff;
  padding: 8px 12px;
  text-align: center;
  width: 100%;
  font-size: 15px;
}

#logon:hover {
  background-color: #004e8c;
  cursor: pointer;
}
.tooltip-container {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: absolute;
  top: 35px;
  background: white;
  z-index: 2;
  min-width: 450px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: left;
  padding: 20px;
}

.tooltip-scroll-container {
  max-height: 100vh;
  overflow: auto;
  position: relative;
  margin: 12px 0 5px;
}

.tooltip-scroll-container p:first-of-type {
  margin-top: 0;
}

.tooltip-scroll-container p:last-of-type {
  margin-bottom: 0;
}

.tooltip-container--bottom {}

.tooltip-container--right {
  right: auto;
  left: -35px;
  -webkit-transform: none;
          transform: none;
}

.tooltip-container--left {
  right: -35px;
  left: auto;
  -webkit-transform: none;
          transform: none;
}

.tooltip-container--top {}

.tooltip-container:after {
  content: '';
  display: block;
  position: absolute;
  top: -20px;
  right: 50%;
  width: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid white;
  -webkit-transform: rotate(90deg) translateY(-50%);
          transform: rotate(90deg) translateY(-50%);
}

.tooltip-container--right:after {
  left: 23px;
  right: auto;
}

.tooltip-container--left:after {
  right: 23px;
  left: auto;
}

/* // ring stuffs */

.ring-container {
  position: relative;
  cursor: pointer;
  width: 15px;
  height: 15px;
  /* background: hotpink; */
}

.ring-container--disabled {
  cursor: not-allowed;
}

.circle {
  width: 15px;
  height: 15px;
  background-color: #62bd19;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

.ringring {
  border: 3px solid #62bd19;
  border-radius: 30px;
  height: 25px;
  width: 25px;
  position: absolute;
  left: -5px;
  top: -5px;
  -webkit-animation: pulsate 1s ease-out;
          animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0.0;
}

.circle {
  background-color: red
}

.ringring {
  border-color: red;
}

.circle--finished {
  background-color: gray;
  -webkit-animation: none;
          animation: none;
}

.ringring--finished {
  border-color: gray;
  -webkit-animation: none;
          animation: none;
}

.circle--active {
  background-color: #62bd19
}

.ringring--active {
  border-color: #62bd19;
}

@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0.0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0.0;
  }
}

.tooltip-header {
  max-width: calc(100% - 33px);
}
#questionaire {
  width: 100%;
  width: 500px;
  max-width: 100%;
  margin: 20px auto 50px;
  display: block;
}

/* // radio buttons */

.custom-checkradio-container {
  /* width: 600px;
  padding: 50px 0;
  margin: 0 auto; */
}

.custom-checkradio-container .radio, .custom-checkradio-container .checkbox {
  /* margin: 40px 0; */
}

/* Custom radio buttons */

.custom-checkradio-container input[type="radio"]+label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 13px;
  margin: 9px 0;
}

.custom-checkradio-container input[type="radio"]+label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -5px;
  left: 0;
  border: 2px solid #e49c3f;
  background-color: #fff;
  border-radius: 50%;
}

.custom-checkradio-container input[type="radio"] {
  display: none !important;
  *display: inline;
}

.custom-checkradio-container input[type="radio"]:checked+label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e49c3f;
}

/* Custom checkbox */

.custom-checkradio-container input[type="checkbox"]+label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 35px;
  margin-right: 15px;
  font-size: 13px;
  margin: 9px 0;
}

.custom-checkradio-container input[type="checkbox"]+label:before {
  content: "";
  display: block;
  width: 22px;
  height: 22px;
  margin-right: 14px;
  position: absolute;
  top: -3px;
  left: 0;
  border: 1px solid #aaa;
  background-color: #fff;
  border-radius: 5px;
}

.custom-checkradio-container input[type="checkbox"] {
  display: none !important;
  *display: inline;
}

.custom-checkradio-container input[type="checkbox"]:checked+label:after {
  content: "✔";
  font-size: 20px;
  line-height: 20px;
  color: #b7b7b7;
  display: block;
  position: absolute;
  top: 0;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
}

.alert {
  padding: 0px 10px;
  border: 2px solid #ec5c5c;
  margin-bottom: 30px;
  background: #f3efef;
  color: #212121;
  border-radius: 5px;
}
.message-container {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: absolute;
  top: 50px;
  background: white;
  z-index: 2;
  width: 500px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: left;
  padding: 20px;
}
h1, h3, h2 {
  /* color: hotpink; */
}
