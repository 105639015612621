/* close icon */

.close {
  position: absolute;
  right: -8px;
  top: -6px;
  width: 31px;
  height: 31px;
  cursor: pointer;
  background: #e8e8e8;
  border-radius: 200px;
}

.close:hover {
  background: #ccc;
}

.close:before, .close:after {
  position: absolute;
  left: 14px;
  top: 6px;
  content: ' ';
  height: 20px;
  width: 3px;
  background-color: #333;
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}