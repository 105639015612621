.message-container {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  position: absolute;
  top: 50px;
  background: white;
  z-index: 2;
  width: 500px;
  left: 50%;
  transform: translateX(-50%);
  text-align: left;
  padding: 20px;
}